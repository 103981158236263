// SelectPrompt.js
import React from 'react';
import { Select } from 'antd';
import './style.css';

const SelectPrompt = ({ className, inputOptions, defaultValue, disabled, loading, allowClear, onChange}) => {
  const handleChange = (value) => {
    onChange(value);
  };

  const selectStyle = {
    width: '100%',
  };

  return (
    <Select
      className={`${className} custom-select-0-radius`}
      popupClassName="custom-select-dropdown-0-radius"
      defaultValue={defaultValue}
      style={selectStyle}
      onChange={handleChange}
      disabled={disabled}
      loading={loading}
      allowClear={allowClear}
      options={inputOptions.map(option => ({
        value: option.value,
        label: option.label,
        disabled: option.disabled,
      }))}
    />
  );
};

export default SelectPrompt;
