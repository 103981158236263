import React from 'react';
import styled from 'styled-components';

const StyledBodyRegular = styled.p`
  color: ${(props) => props.color || 'var(--character-primary-inverse, #FFF)'};
  font-family: var(--body-regular-font-family, Roboto);
  font-size: var(--body-regular-font-size);
  font-style: var(--body-regular-font-style, normal);
  font-weight: var(--body-regular-font-weight, 400);
  line-height: var(--body-regular-line-height, 20px);
  text-align: left;
  margin: 0;
`;

const BodyRegular = ({ color, children }) => (
  <StyledBodyRegular color={color}>
    {children}
  </StyledBodyRegular>
);

export default BodyRegular;
