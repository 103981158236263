import React from "react";
import { Empty } from 'antd';
import EmptyImg from "components/atoms/EmptyImg";
import styled from 'styled-components';

const EmptySimpleWrapper = styled.div`
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;

  .empty-image {
    height: 40px !important;
    position: relative !important;
    width: 64px !important;
  }

  .empty-text {
    color: var(--characterdisabled-placeholder-25);
    font-family: var(--body-regular-font-family);
    font-size: var(--body-regular-font-size);
    font-style: var(--body-regular-font-style);
    font-weight: var(--body-regular-font-weight);
    letter-spacing: var(--body-regular-letter-spacing);
    line-height: var(--body-regular-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  .ant-empty .ant-empty-image {
    margin-bottom: -50px;
  }
`;

const EmptyStatus = ({ className }) => {
  return (
    <EmptySimpleWrapper className={className}>
      <Empty
        image={<EmptyImg className="empty-image" />}
        description={<span className="empty-text">No Data</span>}
      />
    </EmptySimpleWrapper>
  );
};

export default EmptyStatus;
