import React, { useState, useEffect } from 'react';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { Radio, Input } from 'antd';
import TagManager from 'react-gtm-module'; 

import FunctionTitle from 'components/atoms/FunctionTitle';
import UploadImage from 'components/molecules/UploadImage';
import GenerateOption from 'components/organisms/GenerateOption';
import { useAuth } from "context/AuthContext";
import { imageIdState, maskIdState, thumbnailState } from 'states/imageState';
import { isLoadingState, loadingBarGauageState } from 'states/loadingState';
import { RectButton } from "components/common/RectButton";
import { SelectPrompt } from "components/common/SelectPrompt";
import { GenerateNumber, CreditNumber } from 'components/common/GenerateNumber';
import { generateData } from "services/data";
import promptsData from '../../../prompts.json';
import "./style.css";

const GeneratePanel = () => {
  const selectOptions = Object.keys(promptsData).map(key => ({
    value: promptsData[key],
    label: key
  }));
  const [selectedPrompt, setSelectedPrompt] = useState(selectOptions[0].value);
  const [number, setNumber] = useState(4);
  const [uploadedImageData, setUploadedImageData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const setThumbnails = useSetRecoilState(thumbnailState);
  // const [creditBalance, setCreditBalance] = useState(4);
  // const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  // const [selectedThumbnails, setSelectedThumbnails] = useState([]);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState);
  const setLoadingBarGauage = useSetRecoilState(loadingBarGauageState);
  const [fasterMode, setFasterMdoe] = useState(true);
  
  const imageId = useRecoilValue(imageIdState);
  const maskId = useRecoilValue(maskIdState);

  const { session } = useAuth();

  const creditBalance = 4;

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Category Input',
        selectedCategory: selectedCategory,
      }
    });
  }, [selectedCategory]);

  const isGenerateDisabled = () => {
    return (
      !selectedCategory || !imageId || !maskId || (creditBalance - number) < 0
    );
  };
  
  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImageData(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleNumberChange = (newNumber) => {
    setNumber(newNumber);
  };

  const handleResetClick = () => {
    setSelectedPrompt(selectOptions[0].value);
    setUploadedImageData(null);
    setSelectedCategory("");
    setNumber(4);
    // setSelectedThumbnail(null);
    // setSelectedThumbnails([]);
    // setSelectedImage(null);
  };

  const handleGenerateClick = async () => {
    if (isLoading) {
      return;
    }
  
    setIsLoading(true);
    setLoadingBarGauage(0);
    let processedNumber = 0;
    const totalNumber = number;
  
    TagManager.dataLayer({
      dataLayer: {
        event: 'Prompt Selection',
        selectedPrompt: selectedPrompt 
      }
    });
    
    TagManager.dataLayer({
      dataLayer: {
        event: 'Performance Mode',
        performanceMode: fasterMode ? 'Lite' : 'High-Quality'
      }
    });

    // pollStepInfo(imageId, totalNumber);

    try {
      while (processedNumber < totalNumber) {
        const response = await generateData(
          selectedPrompt,
          imageId,
          maskId,
          selectedCategory,
          Math.min(4, totalNumber - processedNumber),
          fasterMode
        );

        const newThumbnails = response.outputs.flatMap(output => output.map(o => ({
          thumbnailId: o.id,
          productType: o.product_type,
          createdAt: o.created_at
        })))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
        setThumbnails(prevThumbnails => [...newThumbnails, ...prevThumbnails]);
  
        processedNumber += response.outputs.length;
        setLoadingBarGauage(processedNumber / totalNumber);
      }
  
      setIsLoading(false);
      setLoadingBarGauage(0);
    } catch (error) {
      console.error('Generation failed:', error);
      setIsLoading(false);
    }
  };

  const onChange = (e) => {
    setFasterMdoe(e.target.value);
  };

  const isUserLoggedIn = () => {
    if (session) {
      if (session.user.id !== process.env.REACT_APP_GUEST_USER_ID) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className="inference-inputs">
      <div className="inference-image">
        <GenerateOption>
          <FunctionTitle>Upload Product Image</FunctionTitle>
          <UploadImage
              onImageUpload={handleImageUpload}
              uploadedImageData={uploadedImageData}
          />
        </GenerateOption>
      </div>
      <div className="inference-options">
        <div className="inference-box">
          <GenerateOption>
            <FunctionTitle>Product Category</FunctionTitle>
            <Input className='category-input'
              placeholder="Type the product category in English"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            />
          </GenerateOption>
          <GenerateOption>
            <FunctionTitle>Templates (Prompts)</FunctionTitle>
            <SelectPrompt
              className="select-instance"
              size="large"
              placeholder="Select"
              defaultValue={selectOptions[0].label}
              inputOptions={selectOptions}
              onChange={setSelectedPrompt}
            />
          </GenerateOption>
          <GenerateOption>
            <FunctionTitle>Number of Generation</FunctionTitle>
            <div className="number-slider">
              <GenerateNumber
                min={1}
                max={creditBalance > 100 ? 100 : creditBalance}
                step={1}
                value={number}
                onChange={handleNumberChange}
                disabled={!isUserLoggedIn()}
              />
              <CreditNumber
                size="large"
                disabled={!isUserLoggedIn()}
                value={number}
                min={1}
                max={creditBalance > 100 ? 100 : creditBalance}
                onChange={handleNumberChange}
              />
            </div>
          </GenerateOption>
          <GenerateOption>
            <FunctionTitle>Performance Mode</FunctionTitle>
              <Radio.Group onChange={onChange} value={fasterMode}>
                <Radio value={true}>Lite</Radio>
                <Radio value={false}>High-Quality</Radio>
              </Radio.Group>
          </GenerateOption>
          <GenerateOption>
            <FunctionTitle>Credit Balance</FunctionTitle>
            <p className="credit-balance">
              <span className="remained-balance">{creditBalance - number}</span>
              <span className="total-balance"> / {creditBalance}</span>
            </p>
          </GenerateOption>
        </div>
      </div>
      <div className="button-box">
        <div className="button-group-custom">
          <RectButton
            title={isLoading ? "Cancel" : "Generate"}
            type={isLoading ? "primary" : "primary"}
            size="large"
            danger={isLoading}
            disabled={isGenerateDisabled() || isLoading}
            onClick={handleGenerateClick}
          />
          <RectButton
            title="Reset"
            type="default"
            size="large"
            onClick={handleResetClick}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneratePanel;
