import React, { createContext, useContext, useState, useEffect } from "react";
import { useRecoilValue } from 'recoil';
import { rememberMeState } from "states/userState";
import { supabase } from 'services/supabaseClient'

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState(null);
  const rememberMe = useRecoilValue(rememberMeState);

  useEffect(() => {

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
        // sign out or user deleted, delete cookies
        const expires = new Date(0).toUTCString();
        document.cookie = `auth_token=; Domain=.mixme.ai; path=/; expires=${expires}; SameSite=Lax; secure`;
        document.cookie = `refresh_token=; Domain=.mixme.ai; path=/; expires=${expires}; SameSite=Lax; secure`;
      } 
      else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
        // signed in or token refreshed, set cookies
        const maxAge = rememberMe ? 365 * 24 * 60 * 60 : 24 * 60 * 60;
        document.cookie = `auth_token=${session.access_token}; Domain=.mixme.ai; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
        document.cookie = `refresh_token=${session.refresh_token}; Domain=.mixme.ai; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
      }

      updateStateBasedOnSession(session);
    });

    const recoverAndObserveSession = async () => {
      // 쿠키에서 액세스 토큰과 리프레시 토큰 복구
      const cookies = document.cookie.split(/\s*;\s*/).map(cookie => cookie.split('='));
      const accessTokenCookie = cookies.find(x => x[0] === 'auth_token');
      const refreshTokenCookie = cookies.find(x => x[0] === 'refresh_token');
      
      if (accessTokenCookie && refreshTokenCookie) {
        await supabase.auth.setSession({
          access_token: accessTokenCookie[1],
          refresh_token: refreshTokenCookie[1],
        });
      }

      // 초기 세션 상태 확인
      const { data: { session: initialSession } } = await supabase.auth.getSession();

      setSession(initialSession);
      updateStateBasedOnSession(initialSession);
      
      setLoading(false);
    };

    recoverAndObserveSession();


    return () => {
      if (subscription && typeof subscription.unsubscribe === 'function') {
        subscription.unsubscribe();
      }
    };
  }, [rememberMe]);

  const updateStateBasedOnSession = (session) => {
    setSession(session);
    if (session && session.user) {
      setUserId(session.user.id);
      setUserName(session.user.user_metadata.username || 'Demo');
    } else {
      setUserId(null);
      setUserName('Demo');
    }
  };
  
  const value = {
    signUp: (data) => supabase.auth.signUp(data),
    signIn: (data) => supabase.auth.signInWithPassword(data),
    signOut: (data) => supabase.auth.signOut(data),
    resend: (data) => supabase.auth.resend(data),
    reset: (data) => supabase.auth.resetPasswordForEmail(data),
    session,
    userId,
    userName
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}