import React from 'react';
import { Button as AntButton } from 'antd';
import PropTypes from 'prop-types';
import './style.css';

const RectButton = ({
  title = "Button Title",
  type = "primary",
  shape = "default",
  size = "middle",
  stateProp = "normal",
  danger = false,
  ghost = false,
  icon,
  className,
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled = false,
}) => {
  return (
    <AntButton
      type={type}
      shape={shape}
      size={size}
      danger={danger}
      ghost={ghost}
      icon={icon}
      className={`${className} custom-button-${stateProp} custom-button-0-radius`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
    >
      {title}
    </AntButton>
  );
};

RectButton.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(["primary", "dashed", "link", "text", "default"]),
  shape: PropTypes.oneOf(["circle", "round", "default"]),
  size: PropTypes.oneOf(["large", "middle", "small"]),
  stateProp: PropTypes.oneOf(["active", "normal", "disabled", "hover", "animating"]),
  danger: PropTypes.bool,
  ghost: PropTypes.bool,
  icon: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  disabled: PropTypes.bool
};

export default RectButton;
