import React from 'react';
import ReactDOM from 'react-dom';
import ModalBackground from 'components/atoms/ModalBackground';
import ImageTool from 'components/templates/ImageTool';

const ImageEditor = ({ maskSrc, onClose, imageId, maskId }) => {
  return ReactDOM.createPortal(
    <ModalBackground onClick={onClose}>
      <ImageTool
        maskSrc={maskSrc}
        onClose={onClose}
        imageId={imageId}
        maskId={maskId}
      />
    </ModalBackground>,
    document.getElementById('modal-root')
  );
};

export default ImageEditor;
