// states/imageState.js
import { atom } from 'recoil';

export const imageIdState = atom({
  key: 'imageIdState',
  default: null,
});

export const maskIdState = atom({
  key: 'maskIdState',
  default: null,
});

export const imageSrcState = atom({
  key: 'imageSrcState',
  default: null,
});

export const sourceImageState = atom({
  key: 'sourceImageState',
  default: null,
});

export const combinedImageSrcState = atom({
  key: 'combinedImageSrcState',
  default: null,
});

export const thumbnailState = atom({
  key: 'thumbnailState',
  default: [],
});
