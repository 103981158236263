import { useAuth } from "context/AuthContext";

const useGuestLogin = () => {
  const { signIn } = useAuth();

  const guestLogin = async () => {
    try {
      const { error } = await signIn({
        email: process.env.REACT_APP_GUEST_EMAIL,
        password: process.env.REACT_APP_GUEST_PW
      });
      if (error) {
        console.error("Guest login failed:", error);
        return { success: false, error: error };
      }
      console.log("Successfully logged in as guest.");
      return { success: true };
    } catch (error) {
      console.error("Error during guest sign-in:", error);
      return { success: false, error: error };
    }
  };

  return guestLogin;
};

export default useGuestLogin;
