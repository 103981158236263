import React from 'react';
import { InputNumber as AntInputNumber } from 'antd';
import PropTypes from 'prop-types';
import './style.css';

const CreditNumber = ({ size, disabled, value, min, max, onChange }) => {
  const getSizeClass = () => {
    switch (size) {
      case 'large':
        return 'credit-number-large custom-button-0-radius';
      case 'small':
        return 'credit-number-small custom-button-0-radius';
      default:
        return 'credit-number-medium custom-button-0-radius';
    }
  };

  return (
    <AntInputNumber
      className={getSizeClass()}
      disabled={disabled}
      value={value}
      min={min}
      max={max}
      onChange={onChange}
    />
  );
};

CreditNumber.propTypes = {
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  formatter: PropTypes.func,
  parser: PropTypes.func,
  onChange: PropTypes.func,
};

CreditNumber.defaultProps = {
  size: 'medium',
  disabled: false,
  defaultValue: 4,
  min: 1,
  max: 100,
  formatter: value => value,
  parser: value => value,
};

export default CreditNumber;
