import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'antd';
import HomeIcon from 'components/atoms/HomeIcon';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
`;

const TitleComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 283px;
  height: 720px;
`;


const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Logo = styled.div`
  color: var(--Neutral-10, #262626);
  font-family: Trispace;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;

const WelcomeMessage = styled.div`
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const SignupComponent = styled.div`
  display: flex;
  height: 219.25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const AwesomeMessage = styled.div`
  color: #000;
  text-align: center;

  font-family: Roboto;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
`;

const ResultMessage = styled.div`
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;


const ButtonText = styled.span`
  color: var(--character-primary-inverse, #FFF);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

const StyledButton = styled(Button)`
  width: 281px;
  height: 38px;
  border: 1px solid var(--Primary-6, #1890FF);
  background: var(--Primary-6, #1890FF);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 2px solid var(--Primary-6, #1890FF); /* Diagonal border */
    border-radius: 2px;
  }

  &:hover, &:focus {
    background: var(--Primary-5, #40a9ff);
    border-color: var(--Primary-5, #40a9ff);
    &:before {
      border-color: var(--Primary-5, #40a9ff);
    }
  }
`;


const FooterComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const CompanyInfo = styled.div`
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const Welcome = () => {
  const navigate = useNavigate();

  const moveMain = () => {
    navigate('/');
  }

  return (
    <MainContainer>
      <TitleComponent>
        <Title>
          <Logo>mixme</Logo>
          <WelcomeMessage>Welcome to Mixme</WelcomeMessage>
        </Title>
        <SignupComponent>
          <AwesomeMessage>
            🥳
            <br />
            Awesome!
          </AwesomeMessage>
          <ResultMessage>You're in.</ResultMessage>
          <StyledButton type="primary" onClick={moveMain}>
            <ButtonText>Let's get started</ButtonText>
          </StyledButton>
        </SignupComponent>
        <FooterComponent>
          <HomeIcon/>
          <CompanyInfo>Copyright ©2023 Mixme. All Rights Reserved.</CompanyInfo>
        </FooterComponent>
      </TitleComponent>
    </MainContainer>
  );
};

export default Welcome;
