import React from 'react';
import styled from 'styled-components';

const StyledEditIcon = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const EditIcon = ({ onClick }) => {
  return (
    <StyledEditIcon
      src="/images/pen-circle.svg"
      alt="Edit"
      onClick={onClick}
    />
  );
};

export default EditIcon;
