import React from "react";
import styled from "styled-components";
import { LargeMountCredit } from "./sections/LargeMountCredit/LargeMountCredit";
import { WalletHeader } from "./sections/WalletHeader";
import { PaymentCards } from "./sections/PaymentCards";
import { PaymentHistoryTable } from "./sections/PaymentHistoryTable";

const StyledWallet = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding: 1px;
  position: relative;
  // width: 100%;

  & .upload-drag {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--neutral-2);
    border: 1px solid;
    border-color: var(--neutral-5);
    border-radius: 2px;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 24px;
    overflow: hidden;
    padding: 16px 24px;
    position: relative;
    width: 100%;
  }

  & .payment-history-wrapper {
    width: 100%;
    text-align: left;
  }
  
  & .payment-history {
    color: var(--primary-6);
    font-family: var(--h5-medium-font-family);
    font-size: var(--h5-medium-font-size);
    font-style: var(--h5-medium-font-style);
    font-weight: var(--h5-medium-font-weight);
    letter-spacing: var(--h5-medium-letter-spacing);
    line-height: var(--h5-medium-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
    text-align: left !important;
  }
`;

export const Wallet = () => {
  return (
    <StyledWallet>
      <div className="upload-drag">
        <WalletHeader />
        <PaymentCards />
        <LargeMountCredit />
        <div className="payment-history-wrapper">
          <div className="payment-history">Payment History</div>
        </div>
        <PaymentHistoryTable />
      </div>
    </StyledWallet>
  );
};
