// states/imageState.js
import { atom } from 'recoil';

export const isLoadingState = atom({
  key: 'isLoadingState',
  default: false,
});

export const loadingBarGauageState = atom({
  key: 'loadingBarGauageState',
  default: 0.0,
});
