// services/fileService.js
const checkFileType = (file) => {
  const validTypes = [
    'image/png', 
    'image/jpeg', 
    'image/jpg', 
    'image/webp',
    'image/gif', 
    'image/bmp', 
    'image/tiff',
    'image/heif',
    'image/heic'
  ];
  return validTypes.includes(file.type);
};

export { checkFileType };
