import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from "antd";

const StyledButton = styled.div`
  button.ant-btn {
    border-radius: ${(props) => props.borderRadius || '2px'};
    
    ${(props) =>
      props.type === 'secondary' &&
      css`
        border: 1px solid var(--Neutral-5, #D9D9D9);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
      `}
  }
`;

const ButtonAtom = ({ type, onClick, borderRadius, children }) => (
  <StyledButton type={type} borderRadius={borderRadius}>
    <Button
      className="button"
      type={type}
      onClick={onClick}
    >
    {children}
    </Button>
  </StyledButton>
);

export default ButtonAtom;
