import React from "react";

const EmptyImg = ({ className }) => {
  return (
    <svg
      className={`empty-image ${className}`}
      fill="none"
      height="40"
      viewBox="0 0 64 40"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_38_423)">
        <path
          className="path"
          d="M32 39.7032C49.6731 39.7032 64 36.5925 64 32.7552C64 28.9179 49.6731 25.8071 32 25.8071C14.3269 25.8071 0 28.9179 0 32.7552C0 36.5925 14.3269 39.7032 32 39.7032Z"
          fill="#F5F5F5"
        />
        <path
          className="path"
          d="M55 13.6653L44.854 2.24866C44.367 1.47048 43.656 1 42.907 1H21.093C20.344 1 19.633 1.47048 19.146 2.24767L9 13.6663V22.8367H55V13.6653Z"
          stroke="#D9D9D9"
        />
        <path
          className="path"
          d="M41.613 16.8128C41.613 15.2197 42.607 13.9046 43.84 13.9036H55V31.9059C55 34.0132 53.68 35.7402 52.05 35.7402H11.95C10.32 35.7402 9 34.0122 9 31.9059V13.9036H20.16C21.393 13.9036 22.387 15.2167 22.387 16.8098V16.8317C22.387 18.4248 23.392 19.7111 24.624 19.7111H39.376C40.608 19.7111 41.613 18.4128 41.613 16.8198V16.8128Z"
          fill="#FAFAFA"
          stroke="#D9D9D9"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_38_423">
          <rect className="rect" fill="white" height="40" width="64" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyImg;