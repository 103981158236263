import React from 'react';
import { Spin } from 'antd';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const CustomSpinIcon = styled.img`
  width: 40px;
  height: 40px;
  animation: ${rotate} 1.5s linear infinite;
`;

const CustomSpin = ({ src, alt }) => {
  const antIcon = <CustomSpinIcon src={src} alt={alt} />;

  return <Spin size="large" indicator={antIcon} />;
};

export default CustomSpin;
