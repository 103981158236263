import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Group, Image, Circle, Transformer } from 'react-konva';
import useImage from 'use-image';
import { useRecoilState, useSetRecoilState } from 'recoil';
import TagManager from 'react-gtm-module';

import RemBGTool from "components/organisms/RemBGTool";
import { selectedButtonState, pointState } from 'states/removeBGState';
import { useSourceImage } from 'context/SourceImageContext';
import { useImageProp } from 'context/ImageProp';
import { useAuth } from "context/AuthContext";
import { remove_bg, confirmMask } from 'services/data';
import { checkerboardSize, opacity, defaultButton } from 'constants/imageCanvas';
import { isLoadingState } from 'states/loadingState';

import styled from "styled-components";


const StyledImageTool = styled.div`
  align-items: flex-start;
  background-color: var(--conditional-item-background-hover);
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 688px;

  & .canvas {
    flex: 0 0 auto;
    position: relative;
    padding: 24px;
  }

  .canvas .uploaded-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  & canvas {
    background-image: 
      linear-gradient(45deg, #ccc 25%, transparent 25%),
      linear-gradient(-45deg, #ccc 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #ccc 75%),
      linear-gradient(-45deg, transparent 75%, #ccc 75%);
    background-size: ${checkerboardSize * 2}px ${checkerboardSize * 2}px;
    background-position: 0 0, 0 ${checkerboardSize}px, ${checkerboardSize}px -${checkerboardSize}px, -${checkerboardSize}px 0px;
  }
`;

const ImageTool = ({ maskSrc, onClose, imageId, maskId }) => {
  const groupRef = useRef(null);
  const transformerRef = useRef(null);
  const stageRef = useRef(null);
  const imageRef = useRef(null);

  const setIsLoading = useSetRecoilState(isLoadingState);
  const {imageUrl, setMaskUrl, setSourceUrl} = useSourceImage();
  const { isReady, setIsReady, transform, setTransform, tmpTransform, setTmpTransform, canvasRatio, setCanvasRatio, adjustedSize, setAdjustedSize } = useImageProp();

  const [newMaskUrl, setNewMaskUrl] = useState(maskSrc);
  const [maskedCanvas, setMaskedCanvas] = useState(null);

  const [selectedButton, setSelectedButton] = useRecoilState(selectedButtonState);
  const stageSize = { width: 640, height: 640 };
  const [points, setPoints] = useRecoilState(pointState);

  const { session } = useAuth();

  const [image] = useImage(imageUrl, 'Anonymous');
  const [mask] = useImage(newMaskUrl, 'Anonymous');

  useEffect(() => {
    setIsLoading(true); // 초기 로딩 시작
  }, []);

  useEffect(() => {
    const group = groupRef.current;
    if (group) {
      // 변환 이벤트 리스너 설정
      const onTransform = () => {
        const x = group.x();
        const y = group.y();
        const rotation = group.rotation();
        const scaleX = group.scaleX();
        const scaleY = group.scaleY();

        setTmpTransform({
          imagePosition: { x, y },
          rotation,
          scaleX,
          scaleY,
        });
      };

      // 'transform' 이벤트에 리스너 추가
      group.on('transform', onTransform);

      // 컴포넌트가 언마운트되거나 useEffect가 다시 실행될 때 이벤트 리스너 제거
      return () => {
        group.off('transform', onTransform);
      };
    }
  }, []);

  useEffect(() => {
    if (image && mask) {
      let newCanvasRatio = canvasRatio;
      if (canvasRatio === null) {
        const imageRatio = Math.min(
          stageSize.width / image.width,
          stageSize.height / image.height
        );
        setCanvasRatio(imageRatio);
        setAdjustedSize({ width: image.width * imageRatio, height: image.height * imageRatio });
        newCanvasRatio = imageRatio;
      }
      
      if (tmpTransform.imagePosition.x === null) {
        const newPosition = {
          x: (stageSize.width - image.width * newCanvasRatio) / 2,
          y: (stageSize.height - image.height * newCanvasRatio) / 2
        };

        setTmpTransform(prev => ({
          ...prev,
          imagePosition: newPosition,
        }));
      }

      // 새 canvas 생성 및 크기 조정
      const canvas = document.createElement('canvas');

      canvas.width = image.width * newCanvasRatio;
      canvas.height = image.height * newCanvasRatio;
      const context = canvas.getContext('2d', { willReadFrequently: true });
  
      // 이미지 로드 및 크기 조정
      context.drawImage(image, 0, 0, image.width * newCanvasRatio, image.height * newCanvasRatio);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      
      context.drawImage(mask, 0, 0, mask.width * newCanvasRatio, mask.height * newCanvasRatio);
      const maskData = context.getImageData(0, 0, canvas.width, canvas.height);
  
      // selectedButton이 'Move'일 때 opacity를 1로 설정
      const effectiveOpacity = selectedButton === 'Move' ? 1 : opacity;
  
      for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i + 3] = effectiveOpacity === 1 ? maskData.data[i] : 255 - (255 - maskData.data[i]) * effectiveOpacity;
      }
  
      context.putImageData(imageData, 0, 0);
      setIsLoading(false);
  
      setMaskedCanvas(canvas);
      setIsReady(true);
    }
  }, [image, mask, newMaskUrl, selectedButton]);  
  
  useEffect(() => {
    if (isReady && selectedButton === 'Move' && canvasRatio !== null) {
      const transformer = transformerRef.current;
      const group = groupRef.current;

      if (transformer && group) { 
        transformer.nodes([group]);
        transformer.getLayer().batchDraw();
      }
    }
  }, [isReady, selectedButton, canvasRatio]);

  const handleDragEnd = (e) => {
    const newPosition = { x: e.target.x(), y: e.target.y() };
    setTmpTransform(prevTransform => ({
      ...prevTransform,
      imagePosition: newPosition,
    }));
  };

  const handleImageClick = async (e) => {
    if (selectedButton === 'Add' || selectedButton === 'Remove') {
      const pointerPosition = imageRef.current.getRelativePointerPosition();
      if (!pointerPosition) return;
  
      const relativeX = pointerPosition.x / adjustedSize.width;
      const relativeY = pointerPosition.y / adjustedSize.height;
  
      const newPoint = { x: relativeX, y: relativeY, label: selectedButton === 'Add' ? 1 : 0 };
      const updatedPoints = [...points, newPoint];
      setPoints(updatedPoints);

      const inputPoints = updatedPoints.map(p => [p.x, p.y]);
      const inputLabels = updatedPoints.map(p => p.label);

      try {
        const userId = session.user.id;
        const response = await remove_bg(imageId, maskId, inputPoints, inputLabels);
        const timestamp = new Date().getTime();
        const tmpMaskUrl = `${process.env.REACT_APP_DATA_URL}/tmp/${userId}/${response.mask_id}.webp?timestamp=${timestamp}`;

        setNewMaskUrl(tmpMaskUrl);
      } catch (error) {
        console.error("API Call Failed:", error);
      }
    }
  };

  const handleReset = () => {
    setNewMaskUrl(maskSrc);
    setTmpTransform(transform);
    setPoints([]);
  };
  
  const handleConfirm = async (isConfirmed) => {
    if (isConfirmed) {
      try {
        setIsLoading(true);
        await confirmMask(imageId, maskId, true, tmpTransform, canvasRatio);
        setMaskUrl(newMaskUrl);
        setTransform(tmpTransform);

        const userId = session.user.id;
        const timestamp = new Date().getTime();
        const newSourceUrl = `${process.env.REACT_APP_DATA_URL}/source/${userId}/${imageId}.webp?timestamp=${timestamp}`;
        setSourceUrl(newSourceUrl);
        setIsLoading(false);
      } catch (error) {
        console.error("Error confirming mask:", error);
      }
    } else if (!isConfirmed) {
      try {
        await confirmMask(imageId, maskId, false);
        setTmpTransform(transform);
      } catch (error) {
        console.error("Error cancelling mask:", error);
      }
    }
    
    setIsReady(false);
    setPoints([]);
    setSelectedButton(defaultButton);

    const closeImageToolStatus = isConfirmed ? 'Done' : 'Cancel';
    TagManager.dataLayer({
      dataLayer: {
        event: 'Close Image Tool',
        closeImageToolStatus: closeImageToolStatus
      }
    });

    onClose();
  };
  
  return (
    <StyledImageTool>
      <RemBGTool
        handleReset={handleReset}
        handleConfirm={handleConfirm}
      />
      <div className="canvas">
        <Stage
          width={stageSize.width}
          height={stageSize.height}
          ref={stageRef}
          style={{
            width: '640px',
            height: '640px',
            background: 'linear-gradient(45deg, #ccc 25%, transparent 25%), linear-gradient(-45deg, #ccc 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #ccc 75%), linear-gradient(-45deg, transparent 75%, #ccc 75%)',
            backgroundSize: `${checkerboardSize * 2}px ${checkerboardSize * 2}px`,
            backgroundPosition: '0 0, 0 8px, 8px -8px, -8px 0px'
          }}
        >
        <Layer>
          <Group
            ref={groupRef}
            x={tmpTransform.imagePosition.x}
            y={tmpTransform.imagePosition.y}
            scaleX={tmpTransform.scaleX}
            scaleY={tmpTransform.scaleY}
            rotation={tmpTransform.rotation}
            draggable={selectedButton === 'Move'}
            onDragEnd={handleDragEnd}
          >
            <Image 
              ref={imageRef}
              image={maskedCanvas} 
              onClick={handleImageClick}
            />
            {
              points.length > 0 && points.map((point, i) => (
                <React.Fragment key={i}>
                  <Circle
                    x={point.x * adjustedSize.width}
                    y={point.y * adjustedSize.height}
                    radius={8}
                    fill={point.label === 1 ? "rgba(186, 231, 255, 0.5)" : "rgba(255, 204, 199, 0.5)"}
                  />
                  <Circle
                    x={point.x * adjustedSize.width}
                    y={point.y * adjustedSize.height}
                    radius={4}
                    fill={point.label === 1 ? "#1890FF" : "#F5222D"}
                  />
                </React.Fragment>
              ))
            }
          </Group>
          {selectedButton === 'Move' && (
            <Transformer
              ref={transformerRef}
              keepRatio
              enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
            />
          )}
        </Layer>
        </Stage>
      </div>
    </StyledImageTool>
  );
};

export default ImageTool;
