import React from "react";
import ExclamationCircle from "components/atoms/ExclamationCircle";

export const MessageBox = ({ children, email }) => {
  return (
    <div className="modal-sending-an">
      <div className="content">
        <ExclamationCircle className="exclamation-circle" color="#1890FF" />
        <div className="right">
          <div className="title">Verification</div>
          <p className="div">
            <span className="text-wrapper">We’ve sent a confirmation email to </span>
            <span className="span">{email} </span>
            <span className="text-wrapper">Please verify your email address.</span>
          </p>
        </div>
      </div>
      <div className="frame">
        {children}
      </div>
    </div>
  );
};
