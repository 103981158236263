import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import "./style.css";

const LoginButton = ({ title, type, onClick }) => {
  return (
    <Button
      className={`login-button ${type === "primary" ? "primary" : "secondary"}`}
      size="large"
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

LoginButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default LoginButton;
