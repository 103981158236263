// GlobalHeader.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Dropdown } from 'antd';
import { UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import { MixMeLogo } from "components/atoms/MixMeLogo";
import { useAuth } from "context/AuthContext";

import "./style.css";

const GlobalHeader = () => {
  const { session, signOut } = useAuth();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState("Login"); 

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleMenuClick = (e) => {
    if (e.key === "logout") {
      handleLogout();
    }
  };
  
  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };

  const handleMoveSetting = () => {
    navigate('/wallet');
  };
  
  const profileMenu = {
    items: [
      {
        key: 'setting',
        label: 'Setting',
        onClick: handleMoveSetting
      },
      {
        key: 'logout',
        label: 'Logout',
        onClick: handleMenuClick
      }
    ]
  };
  
  useEffect(() => {
    if (session) {
      if (session.user.id === process.env.REACT_APP_GUEST_USER_ID) {
        setDisplayName("Login");
      } else {
        setDisplayName(session.user.user_metadata.username || "Anonymous");
      }
    } else {
      setDisplayName("Login");
    }
  }, [session]);

  const handleProfileClick = (e) => {
    if (!session || session.user.id === process.env.REACT_APP_GUEST_USER_ID) {
      navigate('/login');
    } else {
      e.preventDefault();
    }
  };

  return (
    <div className="global-header">
      <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <MixMeLogo className="logo" />
      </div>
      <div className="right-group" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingRight: '24px' }}>
        <QuestionCircleOutlined style={{ marginRight: '8px', color: 'white' }}/>
        <div className="profile" style={{ display: 'flex', alignItems: 'center', padding: '0 12px' }}>
          <Avatar size={24} icon={<UserOutlined />} />
          <Dropdown className='drop-down' menu={profileMenu} trigger={['click']}>
            <div className="user-menu" onClick={handleProfileClick} style={{ marginLeft: '8px', cursor: 'pointer' }}>
              <span className="user-name">{displayName}</span>
              {displayName !== "Login" && <img src="/img/down-icon.svg" alt="Down" className="down-icon" />}
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default GlobalHeader;
