import React, { useState } from "react";
import { Input, Button } from "antd";
import { useAuth } from "context/AuthContext";

import "./style.css";

export const ResetMessage = ({ onClose, email, setEmail, setShowConfirmReset }) => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { reset }  = useAuth();

  const handleCancelClick = () => {
    onClose();
  };

  const handleOkClick = () => {
    if (!email) {
      setIsEmailValid(false);
    } else {
      reset(email);
      setIsEmailValid(true);
      onClose();
      setShowConfirmReset(true);
    }
  };
  
  return (
    <div className="reset-message">
      <div className="title-box">
        <div className="title">Reset Password</div>
      </div>
      <div className="email-box">
        <div className="email-input">
          <Input
            className={`input ${!isEmailValid ? 'invalid-email' : ''}`}
            placeholder="Enter your email"
            size="large"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="button-group">
        <Button className='cancel-button' onClick={handleCancelClick}>Cancel</Button>
        <Button className='ok-button' type="primary" onClick={handleOkClick}>OK</Button>
      </div>
    </div>
  );
};
