import React from "react";
import styled from "styled-components";

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  // width: 100%;

  & .text-wrapper-3 {
    color: var(--primary-6);
    font-family: var(--h5-medium-font-family);
    font-size: var(--h5-medium-font-size);
    font-style: var(--h5-medium-font-style);
    font-weight: var(--h5-medium-font-weight);
    letter-spacing: var(--h5-medium-letter-spacing);
    line-height: var(--h5-medium-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .div-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: flex-end;
    position: relative;
  }

  & .div-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: flex-end;
    position: relative;
  }

  & .credit-number {
    color: black;
    font-family: var(--h5-medium-font-family);
    font-size: var(--h5-medium-font-size);
    font-style: var(--h5-medium-font-style);
    font-weight: var(--h5-medium-font-weight);
    letter-spacing: var(--h5-medium-letter-spacing);
    line-height: var(--h5-medium-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .text-wrapper-4 {
    color: var(--characterprimary-85);
    font-family: var(--h5-regular-font-family);
    font-size: var(--h5-regular-font-size);
    font-style: var(--h5-regular-font-style);
    font-weight: var(--h5-regular-font-weight);
    letter-spacing: var(--h5-regular-letter-spacing);
    line-height: var(--h5-regular-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .text-wrapper-5 {
    color: var(--characterprimary-85);
    font-family: var(--body-regular-font-family);
    font-size: var(--body-regular-font-size);
    font-style: var(--body-regular-font-style);
    font-weight: var(--body-regular-font-weight);
    letter-spacing: var(--body-regular-letter-spacing);
    line-height: var(--body-regular-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
`;

export const WalletHeader = () => {
  return (
    <StyledFrame>
      <div className="text-wrapper-3">Buy Credits</div>
      <div className="div-2">
        <div className="text-wrapper-3">Current Balance:</div>
        <div className="div-3">
          <div className="credit-number">28</div>
          <div className="text-wrapper-4">Credits</div>
        </div>
      </div>
    </StyledFrame>
  );
};
