import React from "react";
import { Button } from "components/common/ButtonNew";
import styled from "styled-components";
import {v4 as uuidv4} from 'uuid';
import { useAuth } from "context/AuthContext";

import * as PortOne from '@portone/browser-sdk/v2';

const StyledPaymentCards = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;

  & .p {
    color: var(--characterprimary-85);
    font-family: var(--body-regular-font-family);
    font-size: var(--body-regular-font-size);
    font-style: var(--body-regular-font-style);
    font-weight: var(--body-regular-font-weight);
    letter-spacing: var(--body-regular-letter-spacing);
    line-height: var(--body-regular-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .card-list {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 20px;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  & .card {
    align-items: center;
    background-color: var(--lightbackgroundpaper);
    border-radius: 4px;
    box-shadow: var(--elevation-1);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    overflow: hidden;
    padding: 24px 40px;
    position: relative;

    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20);

    
    & .button-instance {
      width: 100%;
    }
  }
  

  & .frame-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    position: relative;
  }

  & .text-wrapper-6 {
    color: #000000;
    font-family: var(--h1-medium-font-family);
    font-size: var(--h1-medium-font-size);
    font-style: var(--h1-medium-font-style);
    font-weight: var(--h1-medium-font-weight);
    letter-spacing: var(--h1-medium-letter-spacing);
    line-height: var(--h1-medium-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  & .text-wrapper-7 {
    color: var(--neutral-7);
    font-family: var(--h4-medium-font-family);
    font-size: var(--h4-medium-font-size);
    font-style: var(--h4-medium-font-style);
    font-weight: var(--h4-medium-font-weight);
    letter-spacing: var(--h4-medium-letter-spacing);
    line-height: var(--h4-medium-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  & .button-instance {
    align-self: stretch !important;
    display: flex !important;
    flex: 0 0 auto !important;
    width: 100% !important;
  }

  & .frame-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
  }

  & .text-wrapper-8 {
    color: #000000;
    font-family: var(--h3-regular-font-family);
    font-size: var(--h3-regular-font-size);
    font-style: var(--h3-regular-font-style);
    font-weight: var(--h3-regular-font-weight);
    letter-spacing: var(--h3-regular-letter-spacing);
    line-height: var(--h3-regular-line-height);
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  & .text-wrapper-9 {
    color: var(--neutral-7);
    font-family: var(--body-regular-font-family);
    font-size: var(--body-regular-font-size);
    font-style: var(--body-regular-font-style);
    font-weight: var(--body-regular-font-weight);
    letter-spacing: var(--body-regular-letter-spacing);
    line-height: var(--body-regular-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
`;

const PaymentCards = () => {
  const { userId, userName } = useAuth();

  const productInfo = {
    '20CREDITS': {
      id: 'credit-20',
      name: '20 Credits',
      code: '20CREDITS',
      amount: 9900,
      quantity: 1,
      tag: 'credit'
    },
    '100CREDITS': {
      id: 'credit-100',
      name: '100 Credits',
      code: '100CREDITS',
      amount: 34900,
      quantity: 1,
      tag: 'credit'
    },
    '500CREDITS': {
      id: 'credit-500',
      name: '500 Credits',
      code: '500CREDITS',
      amount: 99000,
      quantity: 1,
      tag: 'credit'
    }
  };
  
  const handlePaymentRequest = async (credits) => {
    const product = productInfo[credits];
    if (!product) {
      console.error("Invalid credits amount:", credits);
      return;
    }

    try {
      const response = await PortOne.requestPayment({
        storeId: process.env.REACT_APP_PORTONE_STORE_ID,
        paymentId: `payment-${uuidv4()}`,
        orderName: `${credits} Credits Purchase`, 
        totalAmount: product.amount,
        currency: 'CURRENCY_KRW',
        channelKey: process.env.REACT_APP_TOSS_CHANNEL,
        isTestChannel: true,
        payMethod: 'CARD',
        products: [product],
        customer: {
          customerId: userId,
          fullName: userName
        },
        noticeUrls: [
          process.env.REACT_APP_PORTONE_NOTICE_URL
        ],
      });

      console.log("Payment Response :", response)
    } catch (error) {
      console.error("Payment request failed:", error);
    }
  };

  return (
    <StyledPaymentCards>
      <p className="p">Select an amount of credits to add your wallet:</p>
      <div className="card-list">
        <div className="card">
          <div className="frame-3">
            <div className="text-wrapper-6">20</div>
            <div className="text-wrapper-7">Credits</div>
          </div>
          <Button size={{ height: '40px', width: '280px' }} onClick={() => handlePaymentRequest('20CREDITS')}>
            Buy
          </Button>
          <div className="frame-4">
            <div className="text-wrapper-8">₩9,900</div>
            <div className="text-wrapper-9">₩495 per image</div>
          </div>
        </div>
        <div className="card">
          <div className="frame-3">
            <div className="text-wrapper-6">100</div>
            <div className="text-wrapper-7">Credits</div>
          </div>
          <Button size={{ height: '40px', width: '280px' }} onClick={() => handlePaymentRequest('100CREDITS')}>
            Buy
          </Button>
          <div className="frame-4">
            <div className="text-wrapper-8">₩34,900</div>
            <div className="text-wrapper-9">₩349 per image</div>
          </div>
        </div>
        <div className="card">
          <div className="frame-3">
            <div className="text-wrapper-6">500</div>
            <div className="text-wrapper-7">Credits</div>
          </div>
          <Button size={{ height: '40px', width: '280px' }} onClick={() => handlePaymentRequest('500CREDITS')}>
            Buy
          </Button>
          <div className="frame-4">
            <div className="text-wrapper-8">₩99,000</div>
            <div className="text-wrapper-9">₩198 per image</div>
          </div>
        </div>
      </div>
    </StyledPaymentCards>
  );
};

export default PaymentCards