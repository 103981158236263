import React from 'react';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import EmptyStatus from "components/molecules/EmptyStatus";
import { selectedImageState } from 'states/generativeState';

const ImageViewWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
`;

const EmptyStatusWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: var(--neutral-2);
  border: 1px solid;
  border-color: var(--neutral-5);
  border-radius: 2px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 16px 0px;
  position: relative;
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    userSelect: none;
  }
`;

const ImageView = () => {
  const selectedImage = useRecoilValue(selectedImageState);

  return (
    <ImageViewWrapper>
      <EmptyStatusWrapper>
        {selectedImage ? (
          <img src={selectedImage} alt="Selected" />
        ) : (
          <EmptyStatus className="empty-component" />
        )}
      </EmptyStatusWrapper>
    </ImageViewWrapper>
  );
};

export default ImageView;