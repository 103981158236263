import React from 'react';
import styled from "styled-components";

const StyledPlusIcon = styled.img`
  width: 14px;
  height: 14px;
  flex-shrink: 0;
`;

const PlusIcon = () => {
  return <StyledPlusIcon src={`${process.env.PUBLIC_URL}/images/plus.svg`} alt="Plus" />;
};

export default PlusIcon;
