import axios from 'axios';
import { supabase } from './supabaseClient'

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/`,
    timeout: 3000,
});

const getAccessTokenAndUserId = async () => {
    const { data } = await supabase.auth.getSession();
    const accessToken = data?.session?.access_token || null;
    const userId = data?.session?.user?.id || null;
    return { accessToken, userId };
};

export const getDataThumbnails = async () => {
    const { accessToken, userId } = await getAccessTokenAndUserId();
    if (!userId) throw new Error("User ID is undefined");
    const response = await instance.get(`/api/data/thumbnails/${userId}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return response.data;
};

const instancegcf = axios.create({
    baseURL: `https://asia-northeast3-mixme-ai.cloudfunctions.net`, // baseURL 변경
    timeout: 30000,
});

export const uploadDatagcf = async (fileBlob, productType) => {
    
    try {
        const { accessToken, userId } = await getAccessTokenAndUserId();

        if (!accessToken || !userId) throw new Error("Authentication or User ID is undefined");

        // const formData = {'image': fileBlob, 'name': 'JUNE', 'fileBlob': fileBlob, 'productType': productType, 'userId': userId};

        const data = JSON.stringify({
            name: 'JUNE', 
            productType: productType, 
            userId: userId
        });

        // Google Cloud Functions 엔드포인트에 맞게 axios 요청 수정
        const response = await instancegcf.post(`/upload_image`, data, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        console.log("response", response.data);
    } catch (error) {
        console.error("Error uploading data:", error);
        throw error;
    }    
};

export const uploadData = async (fileBlob, productType) => {
    const formData = new FormData();
    const { accessToken, userId } = await getAccessTokenAndUserId();
    formData.append('image', fileBlob);
    formData.append('productCategory', productType);
    const response = await instance.post(`/api/data/${userId}`, formData, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        timeout: 30000
    });

    return response.data;
};

export const remove_bg = async (image_id, mask_id, points, labels) => {
    const { accessToken, userId } = await getAccessTokenAndUserId();
    const payload = {
        mask_id: mask_id,
        points: points,
        labels: labels
    };
    try {
        const response = await instance.post(`/api/data/removebg/${userId}/${image_id}`, JSON.stringify(payload), {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            },
            timeout: 30000
        });

        return response.data;
    } catch (error) {
        console.error('Error in remove_bg API call:', error);
        throw error;
    }
}

export const confirmMask = async (imageId, maskId, isConfirm, transform, canvasRatio) => {
    const { accessToken, userId } = await getAccessTokenAndUserId();
    try {
        const response = await instance.post(`/api/data/saveMask/${userId}`, {
            imageId: imageId,
            maskId: maskId,
            isConfirm: isConfirm,
            transform: transform,
            ratio: canvasRatio
        }, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error in saveMask API call:', error);
        throw error;
    }
}

export const generateData = async (prompt, imageId, maskId, category, number, fasterMode) => {
    const { accessToken, userId } = await getAccessTokenAndUserId();
    const payload = {
        prompt: prompt,
        imageId: imageId,
        maskId: maskId,
        category: category,
        number: number,
        fasterMode: fasterMode
    };

    const timeout = number * 2 * 60000;

    try {
        const response = await instance.post(`/api/data/generate/${userId}`, JSON.stringify(payload), {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            timeout: timeout
        });
        return response.data;
    } catch (error) {
        console.error('Error in generateData API call:', error);
        throw error;
    }
}

export const deleteGeneratedData = async (imageId) => {
    const { accessToken, userId } = await getAccessTokenAndUserId();
    try {
        const response = await instance.delete(`/api/data/delete/${userId}/${imageId}`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error deleting generated data:", error);
        throw error;
    }
};

// export async function pollStepInfo(imageId, expectedSteps) {
//     let currentStep = 1;
//     const interval = 10000;
//     const { accessToken, userId } = await getAccessTokenAndUserId();
    
//     const intervalId = setInterval(async () => {
//       try {
//         // Axios 인스턴스를 사용하여 API 요청을 수행합니다.
//         const response = await instance.get(`/api/step-info/${imageId}/${currentStep}`, {
//             headers: {
//               'Authorization': `Bearer ${accessToken}`
//             },
//             timeout: 30000,
//         });
  
//         console.log("response", response);
//         if (response.status === 200) {
//           console.log("Current step info:", response.data);
  
//           // 클라이언트에서 필요한 처리 수행, 예: 진행 상태 업데이트
//           currentStep++;
  
//           if (currentStep >= expectedSteps) {
//             clearInterval(intervalId); // 모든 스텝이 완료되면 폴링 중지
//           }
//         } else {
//           console.error("Failed to fetch step info");
//         }
//       } catch (error) {
//         console.error("Error fetching step info:", error);
//         clearInterval(intervalId); // 에러 발생 시 폴링 중지
//       }
//     }, interval);
// }

export async function pollStepInfo(imageId, expectedSteps) {
    let currentStep = 1;
    let retryCount = 0;
    const maxRetries = 10; // 최대 재시도 횟수
    const interval = 5000; // 폴링 간격(5초)

    const { accessToken } = await getAccessTokenAndUserId();

    const intervalId = setInterval(async () => {
      if (currentStep >= expectedSteps || retryCount >= maxRetries) {
        clearInterval(intervalId); // 모든 스텝이 완료되거나 최대 재시도 횟수에 도달하면 폴링 중지
        return;
      }

      try {
        const response = await instance.get(`/api/step-info/${imageId}/${currentStep}`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            },
            timeout: 30000, // 요청 타임아웃 30초
        });

        console.log("Current step info:", response.data);
        currentStep++; // 다음 스텝으로 이동
        retryCount = 0; // 성공적으로 데이터를 받아왔으므로 재시도 횟수 초기화
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log(`Step info for step ${currentStep} not available yet, retrying...`);
          retryCount++; // 재시도 횟수 증가
        } else {
          // 404가 아닌 다른 종류의 에러 발생 시
          console.error("Error fetching step info:", error);
          clearInterval(intervalId); // 폴링 중지
        }
      }
    }, interval);
}
