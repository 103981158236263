// Button.js
import React from "react";
import { Button as AntdButton } from 'antd';
import styled from "styled-components";

const StyledButton = styled(AntdButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  background-color: #1890FF !important;
  ${({ size }) => size && `
    height: ${size.height} !important;
    width: ${size.width} !important;
  `}
`;

const Button = ({ onClick, children, size }) => {
  return (
    <StyledButton type="primary" onClick={onClick} size={size}>
      {children}
    </StyledButton>
  );
};

export default Button;