import { atom } from 'recoil';

export const selectedImageState = atom({
  key: 'selectedImageState',
  default: null,
});

export const selectedThumbnailsState = atom({
  key: 'selectedThumbnailsState',
  default: [],
});
