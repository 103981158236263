import React from "react";
import "./style.css";

export const Logo = () => {
    return (
        <div className="logo">
            <div className="text-wrapper">mixme</div>
        </div>
    );
};
