import React from "react";
import ExclamationCircle from "components/atoms/ExclamationCircle";
import ModalBackground from "components/atoms/ModalBackground";
import BodyRegular from "components/atoms/BodyRegular";
import Button from "components/atoms/Button";
import styled from 'styled-components';

const Modal = styled.div`
  background-color: var(--conditional-pop-over);
  display: flex;
  width: 352px;
  padding: 32px 32px 24px 32px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  z-index: 1000;
`;

const MessageContent = styled.div`
  display: flex;
  width: 352px;
  align-items: flex-start;
  gap: 16px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;

const Title = styled.div`
  color: var(--character-title-85, rgba(0, 0, 0, 0.85));
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

const MessageBox = ({ title, content, onClose }) => {
  return (
    <ModalBackground>
      <Modal>
        <MessageContent>
          <ExclamationCircle color="#1890FF" />
          <Message>
            <Title>{title}</Title>
            <BodyRegular color='black'>{content}</BodyRegular>
          </Message>
        </MessageContent>
        <Button type='primary' onClick={onClose}>Close</Button>
      </Modal>
    </ModalBackground>
  );
};

export default MessageBox;