import React from 'react';
import styled from 'styled-components';

const ExclamationCircleWrapper = styled.div`
  width: 22px;
  height: 22px;
  flex-shrink: 0;
`;

const ExclamationCircle = () => {
  return (
    <ExclamationCircleWrapper>
      <img alt="Exclamation" src="/images/ExclamationCircle.svg" />
    </ExclamationCircleWrapper>
  );
};

export default ExclamationCircle;
