import React from 'react';
import styled from "styled-components";
import { ReactComponent as DeleteSvg } from 'assets/icons/delete.svg';

const StyledDeleteIcon = styled(DeleteSvg)`
  width: 14px;
  height: 14px;
  color: ${props => props.color};

  & path {
    fill: currentColor;
  }
`;

const DeleteIcon = ({ color = 'currentColor' }) => {
  return <StyledDeleteIcon color={color} alt='delete' />;
};

export default DeleteIcon;
