// src/states/dragState.js
import { atom } from 'recoil';
import { defaultButton } from 'constants/imageCanvas';

export const selectedButtonState = atom({
  key: 'selectedButtonState',
  default: defaultButton,
});

export const pointState = atom({
  key: 'pointState',
  default: [],
});
