import React from "react";
import { Input } from 'antd';
import styled from "styled-components";

const StyledInputWithErrorMsg = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    gap: 4px;

    .error-message {
        text-align: left;
        // min-height: 20px;
        color: var(--characterdanger);
        font-family: var(--footnote-description-font-family);
        font-size: var(--footnote-description-font-size);
        font-style: var(--footnote-description-font-style);
        font-weight: var(--footnote-description-font-weight);
        line-height: var(--footnote-description-line-height);
    }
`;

export const InputWithErrorMsg = ({
    placeholder, 
    prefix, 
    value, 
    onChange, 
    errorMessage, 
    isPassword=false
}) => {
    const InputField = isPassword ? Input.Password : Input;

    return (
        <StyledInputWithErrorMsg>
            <InputField
                className="input"
                status={errorMessage ? "error" : undefined}
                prefix={prefix}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                type={isPassword ? "password" : "text"}
            />
            <div className='error-message'>
                {errorMessage || ''}
            </div>
        </StyledInputWithErrorMsg>
    );
};
