/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const InputPrefix = ({ className }) => {
  return (
    <svg
      className={`input-prefix ${className}`}
      fill="none"
      height="17"
      viewBox="0 0 16 17"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_9_3500)">
        <path
          className="path"
          d="M15.7599 8.47394L8.85986 1.57751L8.39736 1.11501C8.29198 1.01032 8.14947 0.951569 8.00093 0.951569C7.8524 0.951569 7.70989 1.01032 7.60451 1.11501L0.242008 8.47394C0.134028 8.58149 0.048689 8.7096 -0.00897057 8.85068C-0.0666302 8.99176 -0.0954424 9.14296 -0.0937065 9.29536C-0.0865636 9.92393 0.436651 10.4257 1.06522 10.4257H1.82415V16.2418H14.1777V10.4257H14.9527C15.2581 10.4257 15.5456 10.3061 15.7616 10.09C15.868 9.98396 15.9523 9.85785 16.0097 9.719C16.067 9.58014 16.0962 9.4313 16.0956 9.28108C16.0956 8.97751 15.9759 8.69001 15.7599 8.47394ZM9.00093 14.9561H7.00094V11.3132H9.00093V14.9561ZM12.892 9.14001V14.9561H10.1438V10.8846C10.1438 10.49 9.82415 10.1704 9.42951 10.1704H6.57236C6.17772 10.1704 5.85808 10.49 5.85808 10.8846V14.9561H3.10986V9.14001H1.39558L8.00272 2.53822L8.41522 2.95072L14.6081 9.14001H12.892Z"
          fill="#1890FF"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_9_3500">
          <rect className="rect" fill="white" height="16" transform="translate(0 0.599976)" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
