import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import JSZip from 'jszip';
import { Row, Col } from 'antd';
import { saveAs } from 'file-saver';
import TagManager from 'react-gtm-module';

import { DownloadIcon, DeleteIcon } from 'components/atoms';
import FunctionTitle from 'components/atoms/FunctionTitle';
import EmptySimple from "components/molecules/EmptyStatus";
import { isLoadingState, loadingBarGauageState } from 'states/loadingState';
import { selectedImageState, selectedThumbnailsState } from 'states/generativeState';
import { thumbnailState } from 'states/imageState';
import { getDataThumbnails, deleteGeneratedData } from "services/data";
import { useAuth } from "context/AuthContext";

import DataButton from "components/common/DataButton";


const ThumbnailComponent = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--neutral-2);
  border: 1px solid;
  border-color: var(--neutral-5);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow: hidden;
  padding: 16px 8px 16px 16px;
  position: relative;

  &.empty {
    align-items: center;
  }
`;

const GenerativeDownloadDelete = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 16px);
`;

const Thumbnails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 500px;
  overflow-y: auto;
  padding: 16px;
  align-items: flex-start;
  justify-content: flex-start;

  &.center-justify {
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const ThumbnailCol = styled(Col)`
  display: flex;
  justify-content: center;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  margin: -4px 4px;
  transition: background-color 0.3s;

  &.selected {
    background-color: #40A9FF;
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const ThumbnailImage = styled.img`
  margin: 8px;
  max-width: 100%;
  max-height: 100%;
`;

const GenerativeImages = () => {
  const [thumbnails, setThumbnails] = useRecoilState(thumbnailState);
  const [selectedThumbnails, setSelectedThumbnails] = useRecoilState(selectedThumbnailsState);
  const setSelectedImage = useSetRecoilState(selectedImageState);
  const setIsLoading = useSetRecoilState(isLoadingState);
  const loadingBarGauge = useRecoilValue(loadingBarGauageState);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const { session, userId } = useAuth();

  const isUserLoggedIn = () => {
    if (session) {
      if (session.user.id !== process.env.REACT_APP_GUEST_USER_ID) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  
  const handleDownloadClick = async () => {
    if (selectedThumbnails.length === 1) {
      const thumbnailId = selectedThumbnails[0];
      const imageUrl = `${process.env.REACT_APP_DATA_URL}/generated/${userId}/${thumbnailId}.webp`;
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const imageBitmap = await createImageBitmap(blob);
      const canvas = document.createElement('canvas');
      canvas.width = imageBitmap.width;
      canvas.height = imageBitmap.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(imageBitmap, 0, 0);
      canvas.toBlob((blob) => {
        saveAs(blob, `${thumbnailId}.png`);
      }, 'image/png');

      TagManager.dataLayer({
        dataLayer: {
          event: 'Download Image',
          thumbnailId: thumbnailId
        }
      });
    } else {
      const zip = new JSZip();
      for (const thumbnailId of selectedThumbnails) {
        const imageUrl = `${process.env.REACT_APP_DATA_URL}/generated/${userId}/${thumbnailId}.webp`;
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const imageBitmap = await createImageBitmap(blob);
        const canvas = document.createElement('canvas');
        canvas.width = imageBitmap.width;
        canvas.height = imageBitmap.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(imageBitmap, 0, 0);
        const pngBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
        zip.file(`${thumbnailId}.png`, pngBlob);

        TagManager.dataLayer({
          dataLayer: {
            event: 'Download Image',
            downloadImageID: thumbnailId
          }
        });
      }
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, 'images.zip');
    }
  };

  const handleDeleteClick = async () => {
    const newThumbnails = thumbnails.filter(
      thumbnail => !selectedThumbnails.includes(thumbnail.thumbnailId)
    );
  
    setIsLoading(true);
    for (const thumbnailId of selectedThumbnails) {
      try {
        await deleteGeneratedData(thumbnailId);
      } catch (error) {
        console.error("Error deleting image:", thumbnailId, error);
      }
    }

    setIsLoading(false);
    setThumbnails(newThumbnails);
    setSelectedThumbnails([]);
    setSelectedImage(null);
  };  

  const handleThumbnailClick = (thumbnail, index, event) => {
    // Ctrl 키를 누른 경우: 개별 선택 또는 선택 해제
    if (event.ctrlKey) {
      setSelectedThumbnails(prevSelected => {
        const isSelected = prevSelected.includes(thumbnail.thumbnailId);
        if (isSelected) {
          return prevSelected.filter(id => id !== thumbnail.thumbnailId); // 선택 해제
        } else {
          return [...prevSelected, thumbnail.thumbnailId]; // 선택 추가
        }
      });
    } else if (event.shiftKey && lastSelectedIndex !== null) {
      // Shift 키를 누른 경우: 범위 선택
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);
      const newSelectedThumbnails = thumbnails.slice(start, end + 1).map(thumbnail => thumbnail.thumbnailId);
  
      setSelectedThumbnails(prevSelectedThumbnails => {
        // 중복 선택을 피하기 위해 Set을 사용
        const selectedSet = new Set([...prevSelectedThumbnails, ...newSelectedThumbnails]);
        return Array.from(selectedSet);
      });
    } else {
      // 그 외의 경우: 현재 항목만 선택
      setSelectedThumbnails([thumbnail.thumbnailId]);
      setLastSelectedIndex(index); // 현재 클릭한 항목의 인덱스 저장
    }
    setSelectedImage(`${process.env.REACT_APP_DATA_URL}/generated/${userId}/${thumbnail.thumbnailId}.webp`);
  };
  
  useEffect(() => {
    if (session) {
      const fetchThumbnails = async () => {
        if (!isUserLoggedIn() || !session) {
          return;
        }
        try {
          const data = await getDataThumbnails();
          const sortedThumbnails = data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
  
          setThumbnails(sortedThumbnails);
        } catch (error) {
          console.error("Error fetching thumbnails:", error);
        }
      }

      fetchThumbnails();
    };
  }, [session]);

  return (
    <ThumbnailComponent className={thumbnails.length > 0 ? '' : 'empty'}>
      <div style={{ 
        height: '8px',
        backgroundColor: '#1890FF',
        width: `${loadingBarGauge * 100}%`,
        position: 'absolute',
        top: 0,
        left: 0
      }} />
      <GenerativeDownloadDelete>
        <FunctionTitle>Generative Image</FunctionTitle>
        <div className="download-delete">
          <DataButton
            className="empty-component"
            danger={false}
            shape="default"
            size="small"
            title="Download"
            onClick={handleDownloadClick}
            enabled={selectedThumbnails.length > 0}
            type="link"
            icon={<DownloadIcon />} 
          />
          <DataButton
            className="empty-component"
            danger={true}
            shape="default"
            size="small"
            title="Delete"
            enabled={selectedThumbnails.length > 0} 
            type="link"
            onClick={handleDeleteClick}
            icon={<DeleteIcon />} 
          />
        </div>
      </GenerativeDownloadDelete>
      <Thumbnails className={thumbnails.length > 0 ? '' : 'center-justify'}>
        {thumbnails.length > 0 ? (
          <Row gutter={[16, 16]} justify="start">
            {thumbnails.map((thumbnail, index) => (
              <ThumbnailCol
                xs={12} sm={8} md={6} lg={4} xl={3}
                key={thumbnail.thumbnailId}
                className={selectedThumbnails.includes(thumbnail.thumbnailId) ? 'selected' : ''}
                onClick={(e) => handleThumbnailClick(thumbnail, index, e)}
              >
                <ThumbnailWrapper>
                  <ThumbnailImage 
                    src={`${process.env.REACT_APP_DATA_URL}/generated/${userId}/${thumbnail.thumbnailId}.webp`} 
                    alt={thumbnail.productType} 
                  />
                </ThumbnailWrapper>
              </ThumbnailCol>
            ))}
          </Row>
        ) : (
          <EmptySimple className="empty-component" />
        )}
      </Thumbnails>
    </ThumbnailComponent>
  );
};

export default GenerativeImages;
