import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const DataButton = ({
  title = "Button Title",
  danger = false,
  shape = "default",
  size = "middle",
  type = "default",
  enabled = true,
  className,
  onClick,
  icon
}) => {
  
  return (
    <Button
      className={className}
      danger={danger}
      shape={shape}
      size={size}
      type={type}
      disabled={!enabled}
      onClick={onClick}
      icon={icon} 
    >
      {title}
    </Button>
  );
};

DataButton.propTypes = {
  title: PropTypes.string,
  danger: PropTypes.bool,
  shape: PropTypes.oneOf(["default", "circle", "round"]),
  size: PropTypes.oneOf(["large", "middle", "small"]),
  type: PropTypes.oneOf(["primary", "ghost", "dashed", "link", "text", "default"]),
  enabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.node,
};

export default DataButton;
