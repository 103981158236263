import React from 'react';
import styled from "styled-components";
import { ReactComponent as HomeSvg } from 'assets/icons/home.svg';

const StyledHomeIcon = styled(HomeSvg)`
  width: 16px;
  height: 16px;
`;

const HomeIcon = () => {
  return <StyledHomeIcon alt='home' />;
};

export default HomeIcon;
