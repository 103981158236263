import React from "react";
import { Menu } from 'antd';
import { UserOutlined, WalletOutlined } from '@ant-design/icons';
import { Setting } from "../../../icons/Setting";
import styled from "styled-components";

const StyledMenuWrapper = styled.div`
  & .menu-instance {
    width: 100%;
  }

  & .menu-instance .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    border-radius: 0 !important;
  }

  & .menu-instance .ant-menu-item .anticon {
    margin-right: 8px;
  }

  & .menu-instance .ant-menu-item span {
    text-align: left;
    width: 100%;
  }

  & .header .setting-icon {
    display: flex;
    align-items: center;
    gap: 6px; // Gap between icon and text
  }

  & .header .setting-icon svg {
    height: 20px; // Height of the Setting icon
    width: 20px;
  }
`;

function getItem(label, key, icon) {
  return {
    key,
    icon,
    label,
  };
}

export const SettingMenu = () => {
  const menuItems = [
    getItem('Account', '1', <UserOutlined />),
    getItem('Wallet', '2', <WalletOutlined />),
  ];

  return (
    <StyledMenuWrapper>
      <header className="header">
        <div className="element-jm">
          <div className="setting-icon">
            <Setting/>
            <div className="text-wrapper">Settings</div>
          </div>
        </div>
      </header>
      <Menu
        className="menu-instance"
        defaultSelectedKeys={['2']}
        mode="vertical"
        items={menuItems}
      />
    </StyledMenuWrapper>
  );
};

export default SettingMenu;
