// GenerateNumber.jsx
import React from 'react';
import { Slider } from 'antd';
import PropTypes from 'prop-types';

const GenerateNumber = ({ min, max, step, value, onChange, disabled }) => {
  const marks = {
    4: '4',
    28: '28',
    52: '52',
    100: '100',
  };

  return (
    <div className="components-marks" style={{ width: '100%' }}>
      <Slider
        min={min}
        max={max}
        step={step}
        marks={marks}
        value={value}
        onChange={onChange}
        tooltip={{ open: false }}
        disabled={disabled}
      />
    </div>
  );
};

GenerateNumber.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  disabled: PropTypes.bool,
};

GenerateNumber.defaultProps = {
  min: 1,
  max: 100,
  step: 4,
  disabled: false,
};

export default GenerateNumber;
