import React, { createContext, useContext, useState } from 'react';

const SourceImageContext = createContext();

export const useSourceImage = () => useContext(SourceImageContext);

export const SourceImageContextProvider = ({ children }) => {
  const [sourceUrl, setSourceUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [maskUrl, setMaskUrl] = useState(null);

  return (
    <SourceImageContext.Provider value={{ 
      sourceUrl,
      setSourceUrl,
      imageUrl,
      setImageUrl,
      maskUrl,
      setMaskUrl,
    }}>
      {children}
    </SourceImageContext.Provider>
  );
};
