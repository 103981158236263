import React from 'react';
import { Routes, Route } from "react-router-dom"; 
import { RecoilRoot } from 'recoil';
import { Editor } from "components/pages/Editor";
import { Login } from "./components/pages/Login";
import { Signup } from "./components/pages/Signup";
import { Setting } from "./components/pages/Setting";
import Welcome from "components/pages/Welcome";
import { AuthProvider } from "./context/AuthContext";
import { UploadImageProvider } from './context/UploadImageContext';
import { SourceImageContextProvider } from 'context/SourceImageContext';
import { ImagePropProvider } from 'context/ImageProp';
import RouteChangeTracker from './context/RouteChangeTracker';

import './App.css';

function App() {
  return (
    <RecoilRoot>
      <AuthProvider>
        <SourceImageContextProvider>
          <ImagePropProvider>
            <UploadImageProvider>
              <RouteChangeTracker />
              <div className="App">
                <Routes>
                  <Route path="/" element={<Editor />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/wallet" element={<Setting />} />
                  <Route path="/welcome" element={<Welcome />} />
                </Routes>
              </div>
            </UploadImageProvider>
          </ImagePropProvider>
        </SourceImageContextProvider>
      </AuthProvider>
    </RecoilRoot>
  );
}

export default App;
