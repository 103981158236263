import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import styled from "styled-components";
import { useAuth } from "context/AuthContext";
import { supabase } from "services/supabaseClient";

const StyledTable = styled(Table)`
  width: 100%;
`;

const columns = [
  {
    title: 'Index',
    dataIndex: 'key',
    key: 'index',
  },
  {
    title: 'Credits',
    dataIndex: 'credits',
    key: 'credits',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Date',
    dataIndex: 'datetime',
    key: 'datetime',
  },
  {
    title: 'Invoice',
    dataIndex: 'invoice',
    render: (text, record) => (
      <a href={record.invoiceUrl} target="_blank" rel="noopener noreferrer">Link</a>
    ),
  },
];

const PaymentHistoryTable = () => {
  const { userId } = useAuth();
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    const fetchPaymentData = async () => {
      const { data, error } = await supabase.functions.invoke('fetch-payments', {
        body: { userId: userId },
      });
  
      if (error) {
        console.error("Error fetching payment data:", error);
        return;
      }
  
      if (data && Array.isArray(data.data)) {
        const formattedData = data.data.map((item, index) => ({
          key: String(index+1),
          credits: item.credit,
          price: `${item.amount} KRW`,
          datetime: new Date(item.created_at).toLocaleString(),
          invoiceUrl: item.invoice || '#',
        })).sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
  
        setPaymentData(formattedData);
      } else {
        console.error("Received data is not an array");
      }
    };
  
    fetchPaymentData();
    
    const handleInserts = (payload) => {
      fetchPaymentData();
      console.log("Payment Succeeded:", payload)
    }

    const subscription = supabase.channel('credit_transactions')
    subscription
      .on('postgres_changes', { 
        event: 'INSERT', 
        schema: 'public', 
        table: 'credit_transactions' 
      }, handleInserts)
      .subscribe()

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [userId]);

  return <StyledTable columns={columns} dataSource={paymentData} />;
};
  

export default PaymentHistoryTable;