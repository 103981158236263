import React, { useState, useEffect } from "react";
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

import DragDropContent from 'components/molecules/DragDropContent';
import GeneratePanel from 'components/templates/GeneratorPanel';
import ImageView from "components/templates/ImageView";
import { isDraggingState } from 'states/dragState';
import { isLoadingState } from 'states/loadingState';
import { useAuth } from "context/AuthContext";
import { useUploadImageContext } from 'context/UploadImageContext';
import MessageBox from 'components/organisms/MessageBox';

import GenerativeImages from 'components/templates/GenerativeImages';
import GeneratingStatus from "components/molecules/GeneratingStatus";
import Header from "components/templates/Header";

const Main = styled.div`
  align-items: flex-start;
  background-color: var(--conditionalpage-background);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  position: relative;
  width: 100%;
  height: calc(100% - 48px);
  padding: 16px;
`;

const Panel = styled.div`
  width: 368px;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

const GenerativeImagesContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 8px;
  gap: 8px;
`;

const ImageViewContainer = styled.div`
  height: 60%;
  display: flex;
  flex-direction: column;
`;

const GenerativeImagesSection = styled.div`
  height: 40%;
  display: flex;
  flex-direction: column;
`;

const DragOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Editor = () => {
  const isLoading = useRecoilValue(isLoadingState);
  const isDragging = useRecoilValue(isDraggingState);
  const [signinMessageBox, setSigninMessageBox] = useState(false);

  const { userId } = useAuth();
  const { handleDragEnter, handleDragOver, handleDragLeave, handleDrop } = useUploadImageContext();

  useEffect(() => {
    ReactGA.event({
      category: 'User',
      action: 'Login',
      label: 'User Login',
      userId: userId,
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        userId: userId
      }
    });
  }, [userId]);

  return (
    <Main
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Header/>
      {isDragging && (
        <DragOverlay>
          <DragDropContent />
        </DragOverlay>
      )}
      {signinMessageBox && (
        <MessageBox 
          title="Require Login"
          content={
            <span>
              Downloads are available to registered members only. Please sign in {" "}
              <a 
                href="https://app.mixme.ai/login" 
                style={{ color: 'var(--Conditional-menu-item-text-selected, #1890FF)', textDecorationLine: 'underline' }}
                rel="noopener noreferrer"
              >
                here
              </a>.
            </span>
          }
          onClose={() => setSigninMessageBox(false)}
        />
      )}
      <EditorContainer>
        <Panel>
          <GeneratePanel />
        </Panel>
        <GenerativeImagesContainer>
          <ImageViewContainer>
            <ImageView />
          </ImageViewContainer>
          <GenerativeImagesSection>
            <GenerativeImages />
          </GenerativeImagesSection>
        </GenerativeImagesContainer>
      </EditorContainer>
      {isLoading && <GeneratingStatus />}
    </Main>
  );
};
