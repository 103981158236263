import React, { createContext, useContext, useState } from 'react';

const ImageProp = createContext();

export const useImageProp = () => useContext(ImageProp);

export const ImagePropProvider = ({ children }) => {
  const [canvasRatio, setCanvasRatio] = useState(null);
  const [adjustedSize, setAdjustedSize] = useState({ width: null, height: null });
  const [isReady, setIsReady] = useState(false);
  
  const [transform, setTransform] = useState({
    imagePosition: { x: null, y: null },
    rotation: 0,
    scaleX: null,
    scaleY: null,
  });

  const [tmpTransform, setTmpTransform] = useState({
    imagePosition: { x: null, y: null },
    rotation: 0,
    scaleX: null,
    scaleY: null,
  });

  return (
    <ImageProp.Provider value={{
      isReady, 
      setIsReady,
      transform,
      setTransform,
      tmpTransform, 
      setTmpTransform,
      canvasRatio,
      setCanvasRatio,
      adjustedSize, 
      setAdjustedSize,
    }}>
      {children}
    </ImageProp.Provider>
  );
};
