import React from "react";
import ExclamationCircle from "components/atoms/ExclamationCircle";
import { Button } from "antd";

import "./style.css";

export const ConfirmReset = ({ email, onClose }) => {

  const handleOkClick = () => {
    onClose();
  };

  return (
    <div className="confirm-message">
      <div className="confirm-box">
        <div className="title-box">
          <ExclamationCircle className="exclamation-circle" color="#1890FF" />
          <div className="message-title">
            <div className="title">Verification</div> 
            <div className='content-email'>
              <div className="content">We’ve sent an email for resetting password to </div>
              <div className="email">{email}</div>
            </div>
          </div>
        </div>
        <div className="button-group">
          <Button className='ok-button' type="primary" onClick={handleOkClick}>OK</Button>
        </div>
      </div>
    </div>
  );
};
