import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import TagManager from 'react-gtm-module';

import EditIcon from 'components/atoms/EditIcon';
import UploadButton from 'components/molecules/UploadButton';
import ImageEditor from 'components/pages/ImageEditor';
import { useSourceImage } from 'context/SourceImageContext';
import { imageIdState, maskIdState } from 'states/imageState';
import { combinedImageSrcState, imageSrcState } from 'states/imageState';
import { useUploadImageContext } from 'context/UploadImageContext';

import './style.css';

const UploadImage = ({ uploadedImageData }) => {
  const fileInputRef = useRef(null);

  const {sourceUrl, maskUrl} = useSourceImage();

  const [showImageTools, setShowImageTools] = useState(false);

  const maskId = useRecoilValue(maskIdState);
  const imageId = useRecoilValue(imageIdState);
  const [imageSrc, setImageSrc] = useRecoilState(imageSrcState);
  const [combinedImageSrc, setCombinedImageSrc] = useRecoilState(combinedImageSrcState);

  const { handleFileChange } = useUploadImageContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setImageSrc(uploadedImageData);
  }, [uploadedImageData]);

  
  useEffect(() => {
    const performImageProcessing = async () => {
      if (sourceUrl && maskUrl) {
        try {
          setCombinedImageSrc(sourceUrl);
        } catch (error) {
          console.error("Error in image processing:", error);
        }
      }
    };

    performImageProcessing();
  }, [sourceUrl, maskUrl]);

  const handleUploadClick = (event) => {
    if (!showImageTools) {
      fileInputRef.current.click();
    }
  };
  
  const handleEditIconClick = (event) => {
    event.stopPropagation();
    
    TagManager.dataLayer({
      dataLayer: {
        event: 'Open Image Tool',
        imageId: imageId
      }
    });

    setShowImageTools(!showImageTools);
  };

  return (
    <div
      className="upload-image"
      onClick={handleUploadClick}
    >
      {imageSrc ? (
        <div className="image-container">
          {imageSrc && !combinedImageSrc && (
            <img
              src={imageSrc}
              alt="Original Uploaded"
              className="uploaded-image"
            />
          )}
          {combinedImageSrc && (
            <img
              src={combinedImageSrc}
              alt="Combined Image"
              className="uploaded-image"
            />
          )}
          <EditIcon onClick={handleEditIconClick} />
          {showImageTools && ReactDOM.createPortal(
            <ImageEditor
              maskSrc={maskUrl}
              onClose={() => setShowImageTools(false)}
              imageId={imageId}
              maskId={maskId}
            />,
            document.getElementById('modal-root')
          )}
        </div>
      ) : (
        <>
          <UploadButton />
        </>
      )}
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </div>
  );
};


export default UploadImage;
