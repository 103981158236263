import React from 'react';
import styled from 'styled-components';
import CustomSpin from 'components/atoms/CustomSpin';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const GeneratingStatus = () => {
  return (
    <Overlay>
      <CustomSpin src="/images/circle.svg" alt="loading" />
    </Overlay>
  );
};

export default GeneratingStatus;
