import React from "react";
import { Button } from "components/common/ButtonNew";
// import { Button } from 'antd';
import styled from "styled-components";

const StyledDiv = styled.div`
  align-items: flex-start;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--conditionaldivider);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 0px 0px 24px;
  position: relative;
  width: 100%;

  & .frame-5 {
    align-items: center;
    display: inline-flex;
    gap: 16px;
    position: relative;
  }

  & .text-wrapper-10 {
    color: var(--characterprimary-85);
    font-family: var(--body-regular-font-family);
    font-size: var(--body-regular-font-size);
    font-style: var(--body-regular-font-style);
    font-weight: var(--body-regular-font-weight);
    letter-spacing: var(--body-regular-letter-spacing);
    line-height: var(--body-regular-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .button {
    flex: 0 0 auto !important;
  }
`;

export const LargeMountCredit = () => {

  const handleEmailClick = () => {
    window.location.href = 'mailto:info@ingradient.ai';
  };

  return (
    <StyledDiv>
      <div className="frame-5">
        <p className="text-wrapper-10">Want to purchase a larger amount of credits?</p>
        <Button onClick={handleEmailClick} size={{ height: '22px', width: '83px' }}>
          Contact Us
        </Button>
      </div>
    </StyledDiv>
  );
};
