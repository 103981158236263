import React from 'react';
import { GenerateOption as StyledGenerateOption } from './styled';

const GenerateOption = ({ children }) => {
    return (
        <StyledGenerateOption className='generate-option'>
            {children}
        </StyledGenerateOption>
    );
};

export default GenerateOption;
