import React from 'react';
import styled from "styled-components";

const StyledModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


const ModalBackground = ({ children }) => {
  return (
    <StyledModalBackground>
      <div className="modal-background">
        {children}
      </div>
    </StyledModalBackground>
  );
};

export default ModalBackground;
