import React from "react";
import ArrowDown from "components/atoms/ArrowDown";
import "./style.css";

const DragDropContent = () => {
  return (
    <div className="dragdrop">
      <p className="text-wrapper">Drag and drop a product image anywhere to upload</p>
      <ArrowDown className="arrow-down" color="#FFFFFF" />
    </div>
  );
};

export default DragDropContent;
