import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { RectButton } from "../../common/RectButton";
import ModalBackground from "components/atoms/ModalBackground";
import { InputWithErrorMsg } from "components/common/InputWithErrorMsg";
import { LoginButton } from "components/common/LoginButton";
import { MessageBox } from "components/layout/MessageBox";
import { useAuth } from "context/AuthContext";
import { InputPrefix } from "../../../icons/InputPrefix";
import { Logo } from "../../../icons/Logo";
import "./style.css";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");

  const [userNameError, setUserNameError] = useState("");
  const [loginError, setLoginError] = useState("");
  const [emailError, setEmailError] = useState(""); 
  const [passwordError, setPasswordError] = useState("");
  const [retypedPasswordError, setRetypedPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showPopUp, setShowPopUp] = useState(false);
  const { signUp, resend, session } = useAuth();
  const navigate = useNavigate();

  const handleResendClick = async () => {
    console.log("Resend Clicked");
    try {
      const { data, error } = await resend(email,{
        type: 'signup',
        email: email,
        options: {
          data: {
            username: userName,
          }
        } 
      })
  
      if (error) {
        throw error;
      } 
      console.log("Resend successful:", data);
    } catch (error) {
      console.log("Resend failed:", error.message);
      setLoginError(error.message);
    }
  };  

  const handleCloseClick = () => {
    console.log("Close Clicked");
    setShowPopUp(false);
  };

  const handleSignup = async () => {
    setEmailError("");
    setPasswordError("");
    setUserNameError("");
    setLoginError("");
    
    if (!userName.trim()) {
      setUserNameError("User name is required");
      return;
    }

    if (!email.trim()) {
      setEmailError("Email is required");
      return;
    }

    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      return;
    }

    if (!password) {
      setPasswordError("Password is required");
      return;
    }

    const passwordPolicyRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,16}$/;
    if (!passwordPolicyRegex.test(password)) {
        setPasswordError("Password must be at least 8 characters long and include at least one number and one special character.");
        return;
    }

    if (!confirmPassword) {
      setRetypedPasswordError("Retype password is required");
      return;
    }

    if (password !== confirmPassword) {
      setRetypedPasswordError("Passwords do not match.");
      return;
    }

    try {
      console.log("Registering user:", userName, email, password);

      const { error } = await signUp({ 
        email: email, 
        password: password,
        options: {
          data: {
            username: userName,
          }
        } 
      })
      console.log("Signup successful:", error);
      if (error) {
        throw error;
      }
      setShowPopUp(true);
      console.log("Popup should show now:", showPopUp);
    } catch (error) {
      console.error("Signup or login failed:", error.message);
      setLoginError(error.message);
    }
  };

  useEffect(() => {
    if (session) {
      if (session.user.id !== process.env.REACT_APP_GUEST_USER_ID) {
        console.log("User is authenticated:", session);
        navigate('/');
      }
    }
  }, [session]);

  return (
    <div className="signup">
      <div className="login-wrapper">
        <div className="div-2">
          <div className="div-3">
            <Logo className="logo" />
            <div className="text-wrapper-2">Welcome to MixMe</div>
          </div>
          <div className="form">
            <div className="page-title">Sign Up</div>
            <div className="inputs">
              <InputWithErrorMsg
                placeholder="User Name"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                errorMessage={userNameError}
              />
              <InputWithErrorMsg
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                errorMessage={emailError}
              />
              <InputWithErrorMsg
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errorMessage={passwordError}
                isPassword={true}
              />
              <InputWithErrorMsg
                placeholder="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                errorMessage={retypedPasswordError}
                isPassword={true}
              />
              {loginError && <div className="error-message">{loginError}</div>}
            </div>
            <div className="div-4">
                <LoginButton
                  title="Sign Up"
                  type="primary"
                  onClick={handleSignup}
                />
                <LoginButton
                  title="Login"
                  type="secondary"
                  onClick={() => navigate('/login')}
                />
                {showPopUp && ReactDOM.createPortal(
                  <ModalBackground>
                    <MessageBox email={email}>
                      <RectButton
                        title="RESEND"
                        type="primary"
                        size="large"
                        onClick={handleResendClick}
                      />
                      <RectButton
                        title="CLOSE"
                        type="primary"
                        size="large"
                        onClick={handleCloseClick}
                      />
                    </MessageBox>
                  </ModalBackground>,
                  document.getElementById('modal-root')
                )}
            </div>
          </div>
          <footer className="footer">
            <div className="input-prefix-wrapper">
              <InputPrefix className="input-prefix" />
            </div>
            <p className="p">Copyright ©2023 MixMe. All Rights Reserved.</p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Signup;