import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "context/AuthContext";
import { SettingMenu } from "components/templates/SettingMenu";
import Header from "components/templates/Header";
import { Wallet } from "components/templates/Wallet"

import styled from "styled-components";

const StyledSetting = styled.div`
  align-items: flex-start;
  background-color: var(--conditionalpage-background);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  & .frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-grow: 1;
    padding: 16px;
    position: relative;
    // width: 100%;
  }

  & .upload-drag-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 8px;
    position: relative;
  }

  & .upload-drag {
    align-items: center;
    align-self: stretch;
    background-color: var(--neutral-2);
    border: 1px solid;
    border-color: var(--neutral-5);
    border-radius: 2px;
    display: inline-flex;
    // flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    position: relative;
  }

  & .header {
    align-items: flex-start;
    background-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: var(--conditionaldivider);
    border-left-style: none;
    border-right-style: none;
    border-top-style: none;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    padding: 12px 13px;
    position: relative;
    width: 207px;
  }

  & .element-jm {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    position: relative;
  }

  & .icon-wrapper-instance {
    flex: 0 0 auto !important;
  }

  & .setting-instance {
    height: 20px !important;
    position: relative !important;
    width: 20px !important;
  }

  & .text-wrapper {
    color: var(--conditional85l-65d);
    font-family: var(--body-regular-font-family);
    font-size: var(--body-regular-font-size);
    font-style: var(--body-regular-font-style);
    font-weight: var(--body-regular-font-weight);
    letter-spacing: var(--body-regular-letter-spacing);
    line-height: var(--body-regular-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .design-component-instance-node {
    height: 12px !important;
    left: 1px !important;
    top: 1px !important;
    width: 12px !important;
  }

  & .menu-2 {
    height: 12px !important;
    left: 1px !important;
    top: 1px !important;
  }

  & .div-wrapper {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 16px;
    padding: 8px;
    position: relative;
  }

  & .div {
    align-self: stretch;
    background-color: var(--neutral-2);
    border: 1px solid;
    border-color: var(--neutral-5);
    border-radius: 2px;
    flex: 1;
    flex-grow: 1;
    position: relative;
    width: 100%;
  }

  & .menu-instance {
    width: 100%;
  }

  & .menu-instance .ant-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start; 
  }

  & .menu-instance .ant-menu-item .anticon {
    margin-right: -150px;
  }

  & .menu-instance .ant-menu-item span {
    text-align: left;
    width: 100%; 
  }
`;

export const Setting = () => {
  const navigate = useNavigate();
  const { session } = useAuth();

  useEffect(() => {
    if (!session || session.user.id === process.env.REACT_APP_GUEST_USER_ID) {
      navigate('/login');
    }
  }, [session]);

  return (
    <StyledSetting>
      <Header/>
      <div className="frame">
        <div className="upload-drag-wrapper">
          <div className="upload-drag">
            <SettingMenu />
          </div>
        </div>
        <div className="div-wrapper">
          <Wallet />
        </div>
      </div>
    </StyledSetting>
  );
};

export default Setting;
