import styled from 'styled-components';

const FunctionTitle = styled.div`
  color: var(--primary-6);
  font-family: var(--h5-medium-font-family);
  font-size: var(--h5-medium-font-size);
  font-style: var(--h5-medium-font-style);
  font-weight: var(--h5-medium-font-weight);
  letter-spacing: var(--h5-medium-letter-spacing);
  line-height: var(--h5-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;

export default FunctionTitle;
