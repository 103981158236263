import React from 'react';
import PlusIcon from 'components/atoms/PlusIcon';
import styled from "styled-components";

const StyledUploadButton = styled.div`
  display: flex;
  height: 172px;
  padding: 40px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const UploadText = styled.div`
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
`;

const UploadButton = ({ size = 1 }) => {
  return (
    <StyledUploadButton>
      <PlusIcon size={size} />
      <UploadText>
        Click here or drag and drop a product image 
        <br />
        anywhere to upload
      </UploadText>
    </StyledUploadButton>
  );
};

export default UploadButton;
