import React from 'react';
import styled from 'styled-components';
import Button from "components/atoms/Button";
import { useRecoilState } from 'recoil';
import { selectedButtonState } from 'states/removeBGState';
import BodyRegular from 'components/atoms/BodyRegular';

const StyledRemBGTool = styled.div`
  align-items: center;
  align-self: stretch;
  background-color: var(--neutral-1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--neutral-5);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 688px;
  padding: 12px 32px;

  & .tool-div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    position: relative;
  }

  & .add-button, & .tool-button, & .reset-button {
    align-items: center;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 48px;
    justify-content: center;
    overflow: hidden;
    padding: 4px;
    position: relative;
    width: 64px;
    transition: background-color 0.3s;
  }

  & .line {
    height: 48px;
    object-fit: cover;
    position: relative;
    width: 1px;
  }

  & .img, & .img-2 {
    height: 16px;
    position: relative;
    width: 16px; // Adjusted for consistency
  }

  & .text-wrapper {
    color: #000000;
    font-family: var(--footnote-description-font-family);
    font-size: var(--footnote-description-font-size);
    font-style: var(--footnote-description-font-style);
    font-weight: var(--footnote-description-font-weight);
    letter-spacing: var(--footnote-description-letter-spacing);
    line-height: var(--footnote-description-line-height);
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }

  .add-button:hover, .tool-button:hover, .reset-button:hover, 
  .add-button.selected, .tool-button.selected {
    background-color: var(--neutral-4);
  }

  & .confirm-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const RemBGTool = ({ handleReset, handleConfirm }) => {
  const [selectedButton, setSelectedButton] = useRecoilState(selectedButtonState);

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  return (
    <StyledRemBGTool>
      <div className="tool-div">
        <div
          className={`add-button ${selectedButton === 'Add' ? 'selected' : ''}`}
          onClick={() => handleButtonClick('Add')}
        >
          <img className="img" alt="Paintbrush solid" src="/img/paintbrush-solid-1.svg" />
          <div className="text-wrapper">Add</div>
        </div>
        <div
          className={`tool-button ${selectedButton === 'Remove' ? 'selected' : ''}`}
          onClick={() => handleButtonClick('Remove')}
        >
          <img className="img" alt="Eraser icon solid" src="/img/eraser-icon-solid-1.svg" />
          <div className="text-wrapper">Remove</div>
        </div>
        <div
          className={`tool-button ${selectedButton === 'Move' ? 'selected' : ''}`}
          onClick={() => handleButtonClick('Move')}
        >
          <img className="img" alt="Image move and scale" src="/img/Arrow_all_ways.svg" />
          <div className="text-wrapper">Move</div>
        </div>
        <img className="line" alt="Line" src="/img/line-5.svg" />
        <div className="reset-button" onClick={handleReset}>
          <img className="img-2" alt="Rotate left solid" src="/img/rotate-left-solid-1.svg" />
          <div className="text-wrapper">Reset</div>
        </div>
      </div>
      <div className="confirm-buttons">
        <Button
          className="button"
          type={'secondary'}
          onClick={() => handleConfirm(false)}
        >
          <BodyRegular color="black">Cancel</BodyRegular>
        </Button>
        <Button
          className="button"
          type={'primary'}
          onClick={() => handleConfirm(true)}
        >
          <BodyRegular color="white">Done</BodyRegular>
        </Button>
      </div>
    </StyledRemBGTool>
  );
};

export default RemBGTool;
