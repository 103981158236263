import React, { useState, useEffect } from "react";
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { InputPrefix } from "../../../icons/InputPrefix";
import { Logo } from "../../../icons/Logo/Logo";
import { LoginButton } from "components/common/LoginButton";
import ModalBackground from "components/atoms/ModalBackground";
import { ResetMessage } from "components/layout/ResetMessage";
import { ConfirmReset } from "components/layout/ConfirmReset";
import { InputWithErrorMsg } from "components/common/InputWithErrorMsg";
import { useAuth } from "context/AuthContext";
import { rememberMeState } from "states/userState";
import { Checkbox } from 'antd';
import { UserOutlined, LockTwoTone } from '@ant-design/icons';
import "./style.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [currentModal, setCurrentModal] = useState(null);
  const { signIn, userId, session } = useAuth();
  const [rememberMe, setRememberMe] = useRecoilState(rememberMeState);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (session && session.user.id !== process.env.REACT_APP_GUEST_USER_ID) {
      navigate('/');
    }
  }, [userId]);

  const moveSignup = () => {
    navigate('/signup');
  }

  const handleLogin = async () => {
    setEmailError(null);
    setPasswordError(null);
  
    if (!email.trim()) {
      setEmailError("Email address is required.");
      return;
    }
    if (!password) {
      setPasswordError("Password is required.");
      return;
    }
  
    signIn({ email, password }, rememberMe).then(({ error }) => {
      if (error) {
        console.error("Login failed:", error.message);
        setPasswordError(error.message);
      }
    }).catch((error) => {
      console.error("Login request failed:", error.message);
      setPasswordError("An unexpected error occurred.");
    });
  }; 
  
  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  const handlePasswordReset = (e) => {
    e.preventDefault();
    setCurrentModal('reset');
  };

  const handleCloseResetMessage = () => {
    setCurrentModal(null);
  };

  const handleShowConfirmReset = () => {
    setCurrentModal('confirm');
  };

  const handleCloseConfirmReset = () => {
    setCurrentModal(null);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const checkRememberMe = (e) => {
    setRememberMe(e.target.checked);
  }

  return (
    <div className="login">
      <div className="login-wrapper">
        <div className="div-2">
          <div className="div-3">
            <Logo className="logo" />
            <div className="text-wrapper-2">Welcome to MixMe</div>
          </div>
          <div className="form">
            <div className="page-title">Log In</div>
            <div className="input-box">
              <InputWithErrorMsg
                placeholder="Email"
                prefix={<UserOutlined style={{ color: '#1890FF' }} />}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                errorMessage={emailError}
              />
              <InputWithErrorMsg
                placeholder="Password"
                prefix={<LockTwoTone style={{ color: '#1890FF' }} />}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                errorMessage={passwordError}
                isPassword={true}
              />
            </div>
            <div className="group">
              <Checkbox className="remember-me" checked={rememberMe} onChange={checkRememberMe}>Remember me</Checkbox>
              <a href="#" className="forgot-password" 
                style={{ 
                  textDecoration: 'none', 
                  fontFamily: 'var(--h5-regular-font-family)', 
                  color: '#1890FF' 
                }}
                onClick={handlePasswordReset}>
                  Forgot password?
              </a>
            </div>
            <div className="login-buttons">
              <LoginButton
                title="Sign Up"
                type="secondary"
                onClick={moveSignup}
              />
              <LoginButton
                title="Sign In"
                type="primary"
                onClick={handleLogin}
              />
            </div>
            <form onSubmit={handleFormSubmit} onKeyPress={handleKeyPress}/>
          </div>
          <footer className="footer">
            <div className="input-prefix-wrapper">
              <InputPrefix className="input-prefix" />
            </div>
            <p className="p">Copyright ©2023 MixMe. All Rights Reserved.</p>
          </footer>
        </div>
        {currentModal === 'reset' && (
          <ModalBackground>
            <ResetMessage onClose={handleCloseResetMessage} email={email} setEmail={setEmail} setShowConfirmReset={handleShowConfirmReset} />
          </ModalBackground>
        )}

        {currentModal === 'confirm' && (
          <ModalBackground>
            <ConfirmReset email={email} onClose={handleCloseConfirmReset} />
          </ModalBackground>
        )}

      </div>
    </div>
  );
}

export default Login;
