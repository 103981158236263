import React from 'react';
import styled from "styled-components";
import { ReactComponent as DownloadSvg } from 'assets/icons/download.svg';

const StyledDownloadIcon = styled(DownloadSvg)`
  width: 14px;
  height: 14px;
  color: ${props => props.color};

  & path {
    fill: currentColor;
  }
`;

const DownloadIcon = ({ color = 'currentColor' }) => {
  return <StyledDownloadIcon color={color} alt='download' />;
};

export default DownloadIcon;
