import React, { createContext, useContext, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import TagManager from 'react-gtm-module';

import { useSourceImage } from 'context/SourceImageContext';
import { useImageProp } from './ImageProp';
import { isDraggingState } from 'states/dragState';
import { isLoadingState } from 'states/loadingState';

import {
  imageIdState, 
  maskIdState, 
  imageSrcState
} from 'states/imageState';

import { checkFileType } from 'services/fileService';
import { useAuth } from 'context/AuthContext';
import useGuestLogin from 'hooks/useGuestLogin';
import { uploadData } from 'services/data';

const UploadImageContext = createContext();

export const useUploadImageContext  = () => useContext(UploadImageContext);

export const UploadImageProvider = ({ children }) => {
  const {setSourceUrl, setImageUrl, setMaskUrl} = useSourceImage();
  const { setIsReady, setTransform, setTmpTransform } = useImageProp();

  const setIsLoading = useSetRecoilState(isLoadingState);
  const setIsDragging = useSetRecoilState(isDraggingState);
  const setImageId = useSetRecoilState(imageIdState);
  const setMaskId = useSetRecoilState(maskIdState);
  const setImageSrc = useSetRecoilState(imageSrcState);

  const { session } = useAuth();
  const guestLogin = useGuestLogin();

  const processFile = async (file) => {
    if (file.size > 10 * 1024 * 1024) { // 10MB in bytes
      alert("File size should not exceed 10MB.");
      return;
    }
    
    // Check file type
    if (!checkFileType(file)) {
      alert("Only one of png, jpeg, jpg, webp, gif, bmp, tiff, heif, or heic file formats are allowed.");
      return;
    }
  
    if (setMaskUrl) {
      setMaskUrl(null);
      setImageId(null);
      setMaskId(null);
    }
    setIsLoading(true);
    let userId;
    if (!session) {
      const { success, error } = await guestLogin();
      if (!success) {
        console.error("Guest login failed:", error);
        alert("Unable to login as guest.");
        return;
      }
      userId = process.env.REACT_APP_GUEST_USER_ID;
    } else {
      userId = session.user.id;
    }
  
    const newOriginalImageSrc = URL.createObjectURL(file);
    setImageSrc(newOriginalImageSrc);
    
    try {
      const response = await uploadData(file, '');
      setImageId(response.image_id);
      setMaskId(response.mask_id);
      const timestamp = new Date().getTime();
      const newImageUrl = `${process.env.REACT_APP_DATA_URL}/products/${userId}/${response.image_id}.webp?timestamp=${timestamp}`;
      setImageUrl(newImageUrl);
      const newMaskUrl = `${process.env.REACT_APP_DATA_URL}/masks/${userId}/${response.mask_id}.webp?timestamp=${timestamp}`;
      setMaskUrl(newMaskUrl);
      const newSourceUrl = `${process.env.REACT_APP_DATA_URL}/source/${userId}/${response.image_id}.webp?timestamp=${timestamp}`;
      setSourceUrl(newSourceUrl);

      // transform 상태를 초기 상태로 리셋
      setIsReady(false);
      setTransform({
        imagePosition: { x: null, y: null },
        rotation: 0,
        scaleX: null,
        scaleY: null,
      });

      setTmpTransform({
        imagePosition: { x: null, y: null },
        rotation: 0,
        scaleX: null,
        scaleY: null,
      });

      setIsLoading(false);
      
      const imageId = response.image_id;
      TagManager.dataLayer({
        dataLayer: {
          event: 'Image_Upload',
          imageId: imageId
        }
      });

    } catch (error) {
      console.error("Error in uploading image:", error);
      alert("Failed to upload image.");
    }
  };

  const onFileDropped = useCallback((file, onImageUpload) => {
    processFile(file);
    if (onImageUpload) onImageUpload(file);
  }, [processFile]);

  const handleFileChange = useCallback((event) => {
    const files = event.target.files;
    if (files.length > 0) {
      onFileDropped(files[0]);
    }
  }, [onFileDropped]);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, [setIsDragging]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsDragging(false);
    }
  }, [setIsDragging]);

  const handleDrop = useCallback((e, onImageUpload) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      onFileDropped(files[0], onImageUpload);
    }
  }, [onFileDropped]);

  return (
    <UploadImageContext.Provider value={{
      handleFileChange,
      handleDragEnter,
      handleDragOver,
      handleDragLeave,
      handleDrop
    }}>
      {children}
    </UploadImageContext.Provider>
  );
};
