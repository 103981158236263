import React from "react";
import "./style.css";

export const MixMeLogo = () => {
    return (
        <div className="box">
            <img className="mixme" alt="MixMe" src={"img/mixme.svg"} />
        </div>
    );
};
